import Link from 'next/link';

import Slider from '@components/common/Slider/Slider';
import cx from 'classnames';
import { Colors } from 'enums/colors';
import { Vehicle } from 'models/Vehicle';

import Button from '../Button/Button';
import SectionWithContainer, {
  SectionWithContainerProps,
} from '../SectionWithContainer/SectionWithContainer';
import VehicleCard from '../VehicleCard/VehicleCard';
import VehicleCardGrid from '../VehicleCardGrid/VehicleCardGrid';

const CarSliderSection = ({
  className,
  vehicles,
  isValidating,
  title,
  ...props
}: SectionWithContainerProps & {
  vehicles: Vehicle[];
  isValidating: boolean;
  title: string;
}) => {
  return (
    <SectionWithContainer
      className={cx(className, 'border-t-4 border-blue-100')}
      {...props}
    >
      <h2 className="mb-8 font-light text-center text-dark-blue-100">
        {title}
      </h2>

      <div className="hidden md:block">
        <Slider
          styles={{
            navigationBackButton: { top: '-200px', left: '5px' },
            navigationNextButton: { top: '-200px', right: '5px' },
          }}
        >
          {vehicles &&
            vehicles.map((vehicle: Vehicle) => (
              <VehicleCard
                vehicle={vehicle}
                key={vehicle.id}
                className="min-w-[350px] mb-2"
              />
            ))}
        </Slider>
      </div>
      <div className="block md:hidden">
        {vehicles && <VehicleCardGrid vehicles={vehicles} />}
      </div>
      {!vehicles && isValidating && <h3>Loading</h3>}
      <div className="flex justify-center mt-4">
        <Link prefetch={false} href="/subscription-cars" passHref>
          <Button color={Colors.green}>BROWSE CARS</Button>
        </Link>
      </div>
    </SectionWithContainer>
  );
};

export default CarSliderSection;
