import Cookies from 'js-cookie';

const getCoordinates = () => {
  return new Promise<GeolocationPosition>(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(
      resolve,
      geolocationPositionError => {
        reject(geolocationPositionError.message);
      },
    );
  });
};

export const getUserCoordinates = async (): Promise<any | undefined> => {
  if (
    !navigator.geolocation ||
    !process.env.NEXT_PUBLIC_GOOGLE_GEOCODING_API_KEY ||
    Cookies.get('state_code')
  ) {
    return;
  }

  const { coords } = await getCoordinates();

  return coords;
};
