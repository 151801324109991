import Link from 'next/link';

import { PageNotFoundIcon } from '@components/common/Icons';
import ErrorMessage from 'constants/ErrorMessage';

import Button from '../Button/Button';

interface ErrorBlockProps {
  statusCode?: number;
}

const ErrorBlock = ({ statusCode = 500 }: ErrorBlockProps) => {
  return (
    <div
      className="my-48 flex flex-col items-center"
      data-cy="message-page-not-found"
    >
      <PageNotFoundIcon className="h-20 w-25 text-coral-100" />
      <h1 className="mb-5 mt-5 font-light text-center text-dark-blue-100">
        Oops! Looks like you <br /> took a wrong turn
      </h1>
      <h4 className="font-light text-center">
        {statusCode === 404 ? ErrorMessage.NotFound : ErrorMessage.Error}
      </h4>
      <strong className="mt-4 mb-5">{`ERROR CODE: ${statusCode}`}</strong>
      <Link prefetch={false} href="/" passHref>
        <Button className="text-white-20 bg-coral-100 p-3 3">
          TAKE ME HOME
        </Button>
      </Link>
    </div>
  );
};

export default ErrorBlock;
