import ErrorBlock from '@components/common/ErrorBlock/ErrorBlock';
import OtherCarsNearYouSection from '@components/common/OtherCarsNearYouSection/OtherCarsNearYouSection';

const Custom404 = () => {
  return (
    <>
      <ErrorBlock statusCode={404} />
      <OtherCarsNearYouSection />
    </>
  );
};

export default Custom404;
