import axios from 'axios';
import Cookies from 'js-cookie';
import useSWR from 'swr';

const fetcher = (url: string, config: any) =>
  axios.get(url, config).then(res => res.data);

const useCarsNearYou = () => {
  const params = {
    state_id: Cookies.get('state_id'),
    state_code: Cookies.get('state_code'),
    suburb: Cookies.get('suburb'),
  };

  const hasGeolocation = params.state_id || params.state_code;

  const { data: carsNearYou, isValidating: isCarNearValidating } = useSWR(
    [hasGeolocation ? '/api/cars-near' : null, { params }],
    fetcher,
  );

  const { data: featuredCars, isValidating: isFeaturedValidating } = useSWR(
    !hasGeolocation || carsNearYou?.length === 0 ? '/api/featured-cars' : null,
    fetcher,
  );

  return {
    data: carsNearYou || featuredCars,
    isValidating: isCarNearValidating || isFeaturedValidating,
  };
};

export default useCarsNearYou;
