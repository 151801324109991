import { useCallback, useEffect } from 'react';

import { captureException } from '@sentry/nextjs';
import axios from 'axios';
import useCarsNearYou from 'hooks/useCarsNearYou';
import Cookies from 'js-cookie';

import { getUserCoordinates } from 'utils/googleGeolocation';

import CarSliderSection from '../CarSliderSection/CarSliderSection';

const OtherCarsNearYouSection = () => {
  const { data: carsNearYou, isValidating } = useCarsNearYou();

  const getLocation = useCallback(async () => {
    try {
      const coordinates = await getUserCoordinates();

      if (!coordinates) {
        return;
      }

      const {
        data: { stateComponent },
      } = await axios.post('/api/google-geocoding', {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      });

      if (stateComponent) {
        Cookies.set('state_code', stateComponent.short_name);
        Cookies.remove('state_id');
        Cookies.remove('suburb');
      }
    } catch (error) {
      captureException(Error(`[Client-Side]: ${error}`));
    }
  }, []);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return (
    <CarSliderSection
      vehicles={carsNearYou}
      isValidating={isValidating}
      title="Other cars near you"
    />
  );
};

export default OtherCarsNearYouSection;
